export default class InstaFeed {
    constructor(selector, options = {}) {
        this.selector = selector;
        this.options = Object.assign(
            {
                api:
                    'https://api.unsplash.com/photos/?client_id=f8ZhgcOgAtUiaLk64mA_QbPM5Xr1PZa3_Zc2Dao1uAQ',
                number: 8,
            },
            options
        );
        this.init();
    }

    init() {
        const getPost = fetch(this.options.api)
            .then((response) => {
                return response.json();
            })
            // Version 2 de behold    
            //.then((feed) => {
            //    const posts = feed.posts;
            //    
            .then((feed) => {
                let posts = feed.posts ?? posts; // Pour gérer V1 et V2 de Behold
                for (let index = 0; index < this.options.number; index++) {
                    const post = posts[index];
                    const item = document.createElement('div');
                    item.classList.add('instafeed__item');
                    this.selector.appendChild(item);

                    const link = document.createElement('a');
                    if (post.permalink) {
                        link.href = post.permalink;
                    } else {
                        link.href = post.links.html;
                    }
                    link.target = '_blank';
                    item.appendChild(link);

                    // check si c'est une image ou une vidéo
                    const image = document.createElement('img');
                    if (post.caption) {
                        image.alt = post.caption;
                    } else {
                        image.alt = post.description;
                    }
                    if (post.dimensions) {
                        image.width = post.dimensions.width;
                    } else {
                        image.width = post.width;
                    }
                    if (post.dimensions) {
                        image.height = post.dimensions.height;
                    } else {
                        image.height = post.height;
                    }

                    if (post.mediaType) {
                        if (post.mediaType === 'VIDEO') {
                            image.src = post.thumbnailUrl;
                        } else {
                            image.src = post.mediaUrl;
                        }
                    } else {
                        image.src = post.urls.regular;
                    }

                    link.appendChild(image);
                }
            })
            .catch((error) => console.log('Erreur : ' + error));
    }
}
