import InstaFeed from '../../sassy/section/instafeed/js/Instafeed.js'

window.InstaFeed = InstaFeed
globalThis.InstaFeed = InstaFeed

document.querySelectorAll('[data-instafeed]').forEach((element) => {
    const value = element.dataset['instafeed']
    switch (value) {
        default:
            new InstaFeed(element, {
                api: 'https://feeds.behold.so/aUNVSDZdnl3wBNWqs1yd',
                number: 6
            })
            break
    }
})
